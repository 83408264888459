import * as i18next from 'i18next'
import * as memoize from 'lodash.memoize'

export const i18nInstance = i18next.createInstance()

const importResource = (resource, language) => {
  return import(/* webpackChunkName: "[request]" */ `../../assets/locale/${resource}_${language}.json`)
}

const importResources = (resources, language) => {
  return Promise.all(resources.map(resource => importResource(resource, language))).then(translations =>
    translations.reduce((ret: object, translation: object) => ({...ret, ...translation}), {}),
  )
}

export default memoize(function i18n(locale: string, resources: string | string[]) {
  return i18nInstance
    .use({
      type: 'backend',
      read: async (language, namespace, callback) => {
        return (Array.isArray(resources) ? importResources(resources, language) : importResource(resources, language))
          .then(translation => callback(null, translation))
          .catch(error => callback(error))
      },
    } as any)
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    })
})
