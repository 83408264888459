import {TaxType} from '@wix/wix-events-commons-statics/dist/types/enums/events'

export const hasSubtotal = (invoice: wix.events.ticketing.Invoice): boolean =>
  Boolean(invoice.discount) || Boolean(invoice.tax)

export const getSubtotal = (invoice: wix.events.ticketing.Invoice): wix.events.Money => {
  const subtotal = {...invoice.subTotal}

  if (invoice.tax?.type === TaxType.INCLUDED) {
    subtotal.amount = (Number(invoice.tax.taxable.amount) + Number(invoice.discount?.amount.amount || 0)).toFixed(2)
  }

  return subtotal
}
