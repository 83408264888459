import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import * as classNames from 'classnames'
import * as React from 'react'
import {EventSummaryProps} from '.'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {getTimeZoneId} from '../../../commons/selectors/event'
import {EVENT_SUMMARY, EVENT_TITLE} from '../../config/data-hooks'
import {getFormattedStartDate, getStartDate, getTitle, isStartDateTbd} from '../../selectors/event'
import {EventText} from '../styled-components/event-text'
import s from './event-summary.scss'

export const EventSummary = ({event, locale, mobile, t}: EventSummaryProps) => (
  <div className={classNames(s.root, {[s.mobile]: mobile})} data-hook={EVENT_SUMMARY}>
    {mobile ? <MobileDate event={event} locale={locale} t={t} /> : <Date event={event} locale={locale} />}
    <div className={s.name}>
      <EventText data-hook={EVENT_TITLE} extraClass={s.nameText}>
        {getTitle(event)}
      </EventText>
    </div>
  </div>
)

const Date = ({event, locale}) => (
  <div className={s.date}>
    <EventText>
      <BrowserOnly>{getFormattedStartDate(event, locale, false)}</BrowserOnly>
    </EventText>
  </div>
)

const MobileDate = ({event, locale, t}) => (
  <div className={s.mobileDateContainer}>
    {isStartDateTbd(event) ? <MobileTbdDate t={t} /> : <MobileScheduledDate event={event} locale={locale} />}
  </div>
)

const MobileTbdDate = ({t}) => (
  <div className={s.tbdDate}>
    <EventText extraClass={s.tbd}>{t('events.tbd-time')}</EventText>
  </div>
)

const MobileScheduledDate = ({event, locale}) => {
  const startDate = getStartDate(event)
  const timeZoneId = getTimeZoneId(event.event)
  const day = DateFormatter.getDay(startDate, timeZoneId, locale)
  const month = DateFormatter.getMonth(startDate, timeZoneId, locale)
  return (
    <BrowserOnly>
      <div className={s.mobileDate}>
        <EventText extraClass={s.day}>{day}</EventText>
        <EventText extraClass={s.month} faded>
          {month}
        </EventText>
      </div>
    </BrowserOnly>
  )
}
