import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import {getFormattedMoney} from '@wix/wix-events-commons-statics/dist/price/formatter'
import classNames from 'classnames'
import * as React from 'react'
import {OrderDetailsProps} from '.'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {getSubtotal, hasSubtotal} from '../../../commons/selectors/order'
import {ORDER_DETAILS} from '../../config/data-hooks'
import * as DH from '../../config/data-hooks'
import {Divider} from '../styled-components/divider'
import {DottedDivider} from '../styled-components/dotted-divider'
import {EventText} from '../styled-components/event-text'
import * as s from './order-details.scss'

const TableHeader = ({t}) => (
  <div className={classNames(s.row)}>
    <div className={s.colTicket}>
      <EventText>{t('order.header.ticket-type')}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{t('order.header.price')}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{t('order.header.quantity')}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{t('order.header.total')}</EventText>
    </div>
  </div>
)

const Item = ({item}) => (
  <div className={s.row}>
    <div className={s.colTicket}>
      <EventText>{item.name}</EventText>
    </div>
    <div className={s.colPrice}>
      <EventText>{getFormattedMoney(item.price)}</EventText>
    </div>
    <div className={s.colQuantity}>
      <EventText>{item.quantity}</EventText>
    </div>
    <div className={s.colTotal}>
      <EventText>{getFormattedMoney(item.total)}</EventText>
    </div>
  </div>
)

export const Summary = ({invoice, t}) => (
  <div className={s.summary}>
    {hasSubtotal(invoice) ? (
      <>
        <div className={s.subtotals}>
          <div className={s.subtotalsRow}>
            <EventText>{t('order.subtotal')}</EventText>
            <EventText>{getFormattedMoney(getSubtotal(invoice))}</EventText>
          </div>
          {invoice.discount ? (
            <div className={s.subtotalsRow}>
              <EventText>{`${t('order.coupon.code')}${invoice.discount.code}`}</EventText>
              <EventText>{`-${getFormattedMoney(invoice.discount.amount)}`}</EventText>
            </div>
          ) : null}
          {invoice.tax ? (
            <div className={s.subtotalsRow} data-hook={DH.TAX}>
              <EventText>{`${invoice.tax.name} (${invoice.tax.rate}%)`}</EventText>
              <EventText>{getFormattedMoney(invoice.tax.amount)}</EventText>
            </div>
          ) : null}
        </div>
        <Divider />
      </>
    ) : null}
    <div className={s.totals}>
      <div>
        <EventText large>{t('order.total')}</EventText>
      </div>
      <div>
        <EventText large>{getFormattedMoney(invoice.grandTotal)}</EventText>
      </div>
    </div>
  </div>
)

export const OrderDetails = ({order, t, timezone, locale}: OrderDetailsProps) => {
  const {orderNumber, created, invoice} = order
  return (
    <div className={s.root} data-hook={ORDER_DETAILS}>
      <DottedDivider />
      <div className={s.content}>
        <div className={s.header}>
          <EventText>{t('order.number', {orderNumber})}</EventText>
          <BrowserOnly>
            <EventText>
              {t('order.date', {
                date: DateFormatter.getYearMonthDay(created, timezone, locale),
              })}
            </EventText>
          </BrowserOnly>
        </div>
        <Divider />
        <TableHeader t={t} />
        <Divider />
        {invoice.items.map(item => (
          <Item key={item.id} item={item} />
        ))}
        <Divider />
        <Summary invoice={invoice} t={t} />
      </div>
    </div>
  )
}
