import * as classNames from 'classnames'
import * as React from 'react'
import {EventDetailsProps} from '.'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {getTimeZoneId} from '../../../commons/selectors/event'
import {FULL_LOCATION, START_DATE} from '../../config/data-hooks'
import {getEventOrders, getFormattedFullLocation, getFormattedStartDate, hasOrder} from '../../selectors/event'
import {OrderDetails} from '../order-details'
import {EventText} from '../styled-components/event-text'
import * as s from './event-details.scss'

export const EventDetails = ({
  event,
  locale,
  mobile,
  primaryButton,
  secondaryButton,
  shareButton,
}: EventDetailsProps) => (
  <>
    <div className={classNames(s.details, {[s.mobile]: mobile})}>
      <div>
        <BrowserOnly>
          <EventText faded extraClass={s.infoText} data-hook={START_DATE}>
            {getFormattedStartDate(event, locale)}
          </EventText>
        </BrowserOnly>
      </div>
      <div className={s.location}>
        <EventText faded extraClass={s.infoText} data-hook={FULL_LOCATION}>
          {getFormattedFullLocation(event)}
        </EventText>
      </div>
      <div className={s.actions}>
        <div className={s.leftActions}>
          {primaryButton}
          <div className={s.actionButton}>{secondaryButton}</div>
        </div>
        {shareButton}
      </div>
    </div>
    {hasOrder(event) && !mobile
      ? getEventOrders(event).map(order => (
          <OrderDetails key={order.orderNumber} order={order} timezone={getTimeZoneId(event.event)} />
        ))
      : null}
  </>
)
